export const isPhone = function (phone) {
    const reg = /^1\d{10}$/
    return reg.test(phone)
}

export const isNumber = function (text) {
    const reg = /^\d+$/
    return reg.test(text)
}

export const formatPhone = function (text) {
    text = text.replace(/\s+/g, '')
    if (text.length > 11) {
        text = text.substr(0, 11)
    }
    let ret = ''
    for(let i = 0; i < text.length; ++i) {
        if (i === 3 || i === 7) {
            ret += ' '
        }
        ret += text[i]
    }
    return ret;
}
