<template>
    <div class="invite-register">
        <img class="bg" :src="InviteBg"/>
        <div class="ctn">
            <img class="app-icon" :src="appIcon"/>
            <div class="app-title">{{appName}}</div>
            <div class="app-desc">高颜值同城交友</div>
            <div class="register">
                <div class="input-container" style="margin-top: 2.5625rem">
                    <input class="input" placeholder="请输入手机号" @input="phoneChange">
                </div>
                <div class="input-container" style="margin-top: 1.75rem">
                    <input class="input" placeholder="请输入验证码" v-model="authCode">
                    <div v-if="leftSec <= 0" class="get-auth-code" :class="{'disable': !isPhoneOk}" @click="getAuthCode">获取验证码</div>
                    <div v-else class="get-auth-code disable" >{{leftSec}}s</div>
                </div>
                <div class="login" @click="login">登录</div>
                <div class="proto-container">
                    <div class="check-box" :class="{'checked': cb}" @click="cb = !cb">
                        <span v-if="cb">
                            <svg t="1622885419723" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2483" width="100%" height="100%"><path d="M426.667 665.003L243.499 481.835a42.667 42.667 0 1 0-60.331 60.33L396.501 755.5a42.667 42.667 0 0 0 60.331 0l426.667-426.667a42.667 42.667 0 1 0-60.331-60.33l-396.501 396.5z" p-id="2484" fill="#7956CB"></path></svg>
                        </span>
                    </div>
                    <div class="proto-ctn">我已阅读并同意面具约会 <span class="link" @click="ua">《用户协议》</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InviteBg from '@/assets/images/invite_bg.png'
import IconGaoYan from '@/assets/images/app_icon_gaoyan.png'
import IconMianJu from '@/assets/images/app_icon_mianju.png'
import { Toast, Indicator } from 'mint-ui';
import {isPhone, isNumber, formatPhone} from "@/util/util";
import { login, sendRegPhoneCode } from "@/api/api";

export default {
    name: "InviteRegister",
    data() {
        return {
            InviteBg,
            cb: false,
            from: 'android',
            phone: '',
            authCode: '',
            leftSec: 0,
            timer: null,
            inviteCode: '',
            captcha: {},
        }
    },
    mounted() {
        const u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        this.from = isiOS ? 'ios': 'android'
        this.inviteCode = this.$route.query.c
    },
    computed: {
        appName() {
            return this.from === 'android' ? '面具约会' : '高颜约会'
        },
        appIcon() {
            return this.from === 'android' ? IconMianJu : IconGaoYan
        },
        agreement() {
            return this.from === 'android' ? '/user-agreement-android' : '/yanruyu/user-agreement'
        },
        privacy() {
            return this.from === 'android' ? '/privacy-android' : '/yanruyu/privacy'
        },
        isPhoneOk() {
            const phone = this.phone.replace(/\s+/g, '')
            return isPhone(phone)
        }
    },
    methods: {
        ua() {
            window.open(this.agreement, '_blank')
        },
        pc() {
            window.open(this.privacy, '_blank')
        },
        getAuthCode() {
            if (!this.isPhoneOk) {
                return
            }
            const phone = this.phone.replace(/\s+/g, '')
            Indicator.open()
            sendRegPhoneCode({ phone, ...this.captcha }).then(data => {
                if (data.code === 401) {
                    this.showTencentCaptcha()
                } else if (data.code === 0) {
                    // 成功后
                    this.leftSec = 60
                    let timeDown = () => {
                        this.leftSec--
                        if (this.leftSec <= 0) {
                            clearInterval(this.timer)
                        }
                    }
                    this.timer = setInterval(timeDown, 1000)
                }
                Indicator.close()
            }).catch(() => {
                Indicator.close()
            })
        },
        phoneChange(input) {
            // console.log('input', input.target.value)
            // console.log('input', input)
            if (isNumber(input.data) || !input.data) {
                this.phone = formatPhone(input.target.value)
                // console.log('phone', this.phone)
            }
            input.target.value = this.phone
        },
        showTencentCaptcha() {
            // eslint-disable-next-line no-undef
            const captcha = new TencentCaptcha('2067627618', (res) => {
                if(res && res.ret === 0) {
                    const result = { randstr:res.randstr, ticket:res.ticket }
                    console.log('result', result)
                    this.captcha = result
                }
            })
            captcha.show()
        },
        login() {
            const phone = this.phone.replace(/\s+/g, '')
            if (!phone) {
                Toast({
                    message: '手机号不能为空',
                })
                return
            }
            if (!isPhone(phone)) {
                Toast({
                    message: '手机号格式不正确',
                })
                return
            }
            if (!this.authCode) {
                Toast({
                    message: '验证码不能为空',
                })
                return
            }
            if (!this.cb) {
                Toast({
                    message: '请先同意用户协议',
                })
                return
            }
            const params = {
                type: 0,
                phone,
                smsCode: this.authCode,
                deviceId: "",
            }
            if (this.inviteCode) {
                params.inviteCode = this.inviteCode
            }
            if (this.form === 'android') {
                params.deviceType = 1
            } else {
                params.deviceType = 2
            }
            Indicator.open()
            login(params).then((data) => {
                console.log('data', data)
                if (data.code === 0) {
                    Toast({
                        message: '注册成功！2秒后跳转至下载页面',
                    })
                    setTimeout(() => {
                        this.$router.push({
                            name: 'WebDownload',
                        })
                    }, 2000)
                }
                Indicator.close()
            }).catch(() => {
                Indicator.close()
            })
        }
    },
    destroyed() {
        clearInterval(this.timer)
    }
}
</script>

<style lang="scss" scoped>
.invite-register {
    position: relative;
    .bg {
        width: 100%;
    }
    .ctn {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .app-icon {
            position: absolute;
            top: 1.1875rem;
            left: 1.3125rem;
            width: 2.75rem;
            height: 2.75rem;
        }
        .app-title {
            position: absolute;
            top: 1.1875rem;
            left: 4.6875rem;
            right: 0;
            width: 12.5rem;
            text-align: left;
            font-size: 1rem;
            //font-weight: bold;
            color: white;
            font-family: PingFangSC-Medium;
        }
        .app-desc {
            position: absolute;
            top: 2.875rem;
            left: 4.6875rem;
            right: 0;
            width: 12.5rem;
            text-align: left;
            font-size: 0.75rem;
            //font-weight: bold;
            color: white;
            font-family: PingFangSC-Medium;
        }
        .register {
            width: 20.3125rem;
            height: 20.0625rem;
            border-radius: 20px;
            background-color: white;
            position: absolute;
            text-align: center;
            left: 0;
            right: 0;
            top: 14.75rem;
            margin: 0 auto 0 auto;
            .input-container {
                position: relative;
                display: inline-block;
                .input {
                    width: 16rem;
                    height: 2.75rem;
                    font-size: 1rem;
                    outline: none;
                    border: none;
                    border-radius: 0;
                    border-bottom: 0.0625rem solid #7956CB;
                }
                .get-auth-code {
                    min-width: 5.8125rem;
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 2.75rem; line-height: 2.75rem; font-size: 0.875rem; color: #7956CB;
                    &.disable {
                        color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
            .login {
                margin-top: 2.8125rem;
                width: 16.5rem;
                border-radius: 1.5rem;
                height: 3rem;
                line-height: 3rem;
                text-align: center;
                color: white;
                font-size: 1rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(270deg, #C479DE 0%, #7456CB 100%);
            }
            .proto-container {
                margin-top: 1.1rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                max-width: 15.75rem;
                .check-box {
                    width: 0.875rem;
                    min-width: 0.875rem;
                    height: 0.875rem;
                    min-height: 0.875rem;
                    background: #FFFFFF;
                    border-radius: 0.125rem;
                    border: 0.0625rem solid rgba(0, 0, 0, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.checked {
                        border-color: #7956CB;
                    }
                    span {
                        font-size: 0.75rem;
                        color: #7956CB;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .proto-ctn {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 0.75rem;
                    text-align: left;
                    margin-left: 0.3125rem;
                    .link {
                        color: #7956CB;
                    }
                }
            }
        }
    }
}
</style>
