import axios from "axios";
import { Toast } from 'mint-ui';

console.log(process.env.VUE_APP_SERVER)

const instance = axios.create({
    baseURL: process.env.VUE_APP_SERVER,
    timeout: 50000, // request timeout
})

instance.interceptors.response.use(
    response => {
        console.log('response', response.data)
        if (response.data instanceof Blob) {
            return response.data
        }
        if (response.data.code === 0) {
            // no op
        } else {
            Toast({
                message: response.data.message + '',
            })
        }
        return response.data
    }
)

export default instance
