import axios from "./axios";

export function login(params) {
    const data = new FormData()
    const keys = Object.keys(params)
    keys.forEach((key) => {
        data.append(key, params[key])
    })
    return axios({
        url: 'dragon/login',
        method: 'post',
        data,
    })
}

export function sendRegPhoneCode(params) {
    return axios({
        url: 'dragon/user/sendRegPhoneCode',
        method: 'get',
        params,
    })
}
